/* eslint-disable linebreak-style */
/* eslint-disable indent */

const { fetchJSON } = require('@bonnet/next/fetch');

export default async function getValidLocationsFromSiteMap(isKbb) {
    let urlsLocationFromSiteMap = null;
    try {
      urlsLocationFromSiteMap = isKbb ? await fetchJSON('/car-dealers/sitemaps/kbb_location_dealers.json') : await fetchJSON('/car-dealers/sitemaps/atc_location_dealers.json');
    } catch (error) {
        return {};
    }
    const validLocationsFromSiteMap = [];
    if (JSON.stringify(urlsLocationFromSiteMap) !== '{}' && urlsLocationFromSiteMap.urls.length > 0) {
        urlsLocationFromSiteMap.urls.forEach((url) => {
          const data = url.split('/').filter((part) => part !== '');
          const cityState = data[data.length - 1];

          validLocationsFromSiteMap.push(cityState);
        });
    }
    return validLocationsFromSiteMap;
}
