/* eslint-disable linebreak-style */
import { fetchExcludedMakes } from '../fetchers';

export default async function excludeMakes(isSeoMakeATC, makes) {
    const exMakes = await fetchExcludedMakes();
    let exMakeCode = exMakes.makes;
    exMakeCode = exMakeCode.map((exmake) => exmake.toLowerCase());
    if (isSeoMakeATC) {
        return makes.filter((make) => !exMakeCode.includes(make.name?.toLowerCase()));
    }
    return makes.filter((make) => !exMakeCode.includes(make.makeName?.toLowerCase()));
}
