import React from 'react';
import dynamic from 'next/dynamic';
import { isKbbBrand } from '../utilities';

const KBBHeaderContainer = dynamic(() => import('./KBBHeaderContainer'));
const ATCHeaderContainer = dynamic(() => import('./ATCHeaderContainer'));

const HeaderContainer = (props) => {
    const {
        brand,
    } = props;

    const Header = isKbbBrand(brand) ? KBBHeaderContainer : ATCHeaderContainer;

    return (
        <Header {...props} />
    );
};

export default HeaderContainer;
