import React from 'react';
import dynamic from 'next/dynamic';
import { isKbbBrand } from '../utilities';

const KBBFooterContainer = dynamic(() => import('./KBBFooterContainer'));
const ATCFooterContainer = dynamic(() => import('./ATCFooterContainer'));

const FooterContainer = (props) => {
    const {
        brand,
    } = props;

    const Footer = isKbbBrand(brand) ? KBBFooterContainer : ATCFooterContainer;

    return (
        <Footer {...props} />
    );
};

export default FooterContainer;
